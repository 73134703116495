

















































































































import AccountDetailsComponent from './AccountDetailsComponent';
export default AccountDetailsComponent;
